import scrollmonitor from 'scrollmonitor';
// import Rellax from 'rellax';
// import animsition from 'animsition';
import Rellax from 'rellax';

import anime from 'animejs';

import Tablesaw from 'tablesaw/dist/tablesaw';


(function($) {
	
	var App = {


		sliderHome: function(){
		
			
		
			

			// var elem = document.querySelector('.slides');

			// var flkty = new Flickity( elem, {
			//   cellSelector: '.slide',
			//   cellAlign: 'left',
			//   contain: true,
			//   wrapAround: true,
			//   prevNextButtons: false,
			// });
		


			// var what = document.querySelector('.bd-content');

			
			// what.innerHTML = 'whatever';



			

			
			
			if($("#whatevertowatch").length > 0) {

				var myElement = document.getElementById("whatevertowatch");

			}

			var searchQuick = document.getElementById('searchquick');

			
			///
			var noHeader = document.getElementById("no-header-v");

			// var noBackground = document.querySelector(".bkgr__fld");

			var elementHeader = scrollMonitor.create( noHeader );

			elementHeader.enterViewport(function() {
				// console.log( 'In??' );
				// noBackground.classList.add(.fixed__parra');
				$( '.bkgr__fld' ).addClass('.fixed__parra');
			});


			elementHeader.exitViewport(function() {
			//	console.log( 'Left' );
				// noBackground.classList.remove('fixed__parra');
				$( '.bkgr__fld' ).removeClass('.fixed__parra');
			});



			

			//////


			// elementWatcher.enterViewport(function() {
			// 		console.log( 'I have entered the viewport' );
			// 		$( '#searchquick' ).removeClass('fixed');

				


					
			// });

			if($("#whatevertowatch").length > 0) {

				var elementWatcher = scrollMonitor.create( myElement );

				elementWatcher.lock();

				elementWatcher.stateChange(function () {
					//console.log( 'I have entered the viewport' );
					$(searchQuick).toggleClass('fixed', this.isAboveViewport)
				});

			}
			// elementWatcher.isAboveViewport(function() {
			// 		console.log( 'I have left the viewport' );
			// 		$( '#searchquick' ).addClass('fixed');
	
			// });


			

		
		},

		afterLoad: function() {

			if ($(".rellax").length > 0) {

				var rellax = new Rellax('.rellax');

			}
		// 	var loaderJs = document.getElementById("loading");

		// 	window.onload = function () {
		// 		l"ader"Js".class

		//    }

	
			
		},



		initNavigation: function () {
	
			if (window.location.href.indexOf("/contact.aspx") > -1) {
				var contactMenu = document.getElementById('header1_A4');
				$(contactMenu).parent().addClass('active');
			} else if (window.location.href.indexOf("/viewbasket.aspx") > -1) {
				var basketMenu = document.getElementById('header1_Divssqll');
				$(basketMenu).parent().addClass('active');
			} else {
				// console.log('whatveer');
			}

			var basketMenu = document.getElementById('basket_checker');



			if ($('#basket_checker') !== '0') {

				$('#basket_checker').each(function() {
					var text = $(this).text();
					$(this).text(text.replace('0', ''));
					
				});

			}
			
			//  if (basketMenu != '0') {


			// 	// var res = $(basketMenu).replace("0", "");


			// 	$(basketMenu).text().replace('0','dollsss'); 


				
			// 	// $(basketMenu).text().replace('0', 'ss');
			// 	console.log(this);
			// 	// text = text.replace(/^(?=(?:[^0]*0){2})([^,]*)0/, '$1');
			// }


			// $('li.nav-item').each(function () {
			// 	$(this).find('a').first().removeAttr("href");
			// 	$(this).find('li').wrapAll(document.createElement("div"));
			// 	$(this).hover(function () {
			// 		$(this).toggleClass('submenu');
			// 	});
			// });
		},





		readMore: function() {
		
				$('.size__main').each(function () {
					var trimLength = 600;
					var trimMargin = 1.2; // don't trim just a couple of words
					if ($(this).text().length > (trimLength * trimMargin)) {
						var html = $(this).html();
						var trimPoint = $(this).html().indexOf(" ", trimLength);
						var newContent = html.substring(0, trimPoint) + '<span class="read-more">' + html.substring(trimPoint) + '</span><span class="toggle"><a class="text-black" href="#">Read More...</a></span>';
						$(this).html(newContent);

						$(this).find('p').css( "display", "none" );
						$(this).find('h2').css( "display", "none" );


						// $('.read-more').parent().css( "display", "block" );
						
						var paraG = $('.size__main').find('p').filter(function () {
							return this.innerHTML.length > 50;
						});
						var hTwo = $('.size__main').find('h2').filter(function () {
							return this.innerHTML.length > 2;
						});


						$(paraG).first().css( "display", "block" );
						$(hTwo).first().css( "display", "block" );


						// var content = $(this).find('allParag').filter(function (index) { return $("p", this).length === 5; });
						// // var allParag = $("p", content).first().text();
						// console.log(content);
						// if ($(allParag).first().length > 5) {
						// 	// $(this).find(allParag)
						// 	console.log('wgatrcreer');
						// } else {
						// 	console.log(content);
						// }
						
					}
				});
				$('.toggle a').click(function (e) {
					e.preventDefault();
					var para = $(this).closest('.size__main');
					var initialHeight = $(this).closest('.size__main').innerHeight();
					para.find('p').css( "display", "block" );
					para.find('h2').css( "display", "block" );
					para.find('.read-more').show();
					para.find('.toggle').hide();
					var newHeight = para.innerHeight();
					para.css('max-height', initialHeight + 'px');
					para.animate({ maxHeight: newHeight }, 300, function () {
						para.css('max-height', 'none');
					});
				});
				
		},





		whatever: function() {

			var $title = $(".link--kumya");
    
			var $numWords = $title.text().split(" ").length;
			
			if (($numWords >= 1) && ($numWords < 20)) {
				$title.css("font-size", "1em");
			}
			else if (($numWords >= 20) && ($numWords < 40)) {
				$title.css("font-size", "0.8em");
			}
			else {
				$title.css("font-size", "0.8em");
			}    

		},




		listJs: function() {
			var options = {
				valueNames: [ 'categories']
			  };
			  
			  var userList = new List('users', options);
		},

		animeJs: function() {


			var noHeader = document.getElementById("no-header-v");

			var productsVar = document.querySelector(".card-deck");
			
			var elementJs = scrollMonitor.create( noHeader );

			// elementJs.enterViewport(function() {
				
			// 	console.log( 'aside enter' );

			// 	anime.timeline()
			// 	.add({
			// 		targets: 'aside',
			// 		scale: [0.3,1],
			// 		opacity: [0,1],
			// 		translateZ: 0,
			// 		easing: "easeOutExpo",
			// 		duration: 600,
			// 		delay: function(el, i, l) { return i * 200; }

			// 	});

			// 	// noBackground.classList.add('fixed__parra');
				
			// });


			elementJs.exitViewport(function() {

				// console.log( 'aside leave' );
				
				anime.timeline()
				.add({
					targets: 'aside',
					scale: [0.3,1],
					opacity: [0,1],
					translateZ: 0,
					easing: "easeOutExpo",
					duration: 600,
					delay: function(el, i, l) { return i * 200; }

				});
				
			});

		
			
			

		},


		anchorJs: function() {



			window.onload = function() {
				if(location.hash) { $(location.hash).trigger('click'); }
			};
			
			$('.cd-top').click(function(){
				// e.preventDefault();
				var desiredHeight = $(window).height() - 100;
				$('body, html').animate({
					scrollTop: $('.navbar ').offset().top - desiredHeight
				}, 1000);
			});
		
			function scrollToElement(ele) {
				$(window).scrollTop(ele.offset().top).scrollLeft(ele.offset().left);
			}

			
		}




	};

	var SH = {

	    'common': {

	      init: function() {
			 
			  App.listJs();
			  App.anchorJs();
			  App.whatever();
			  App.readMore();
			  App.initNavigation();
	      },
	      finalize: function() {
					App.sliderHome();
					App.afterLoad();
					App.animeJs();
	      }
	    }
	};

	var UTIL = {
	    fire: function(func, funcname, args) {
	      var fire;
	      var namespace = SH;
	      funcname = (funcname === undefined) ? 'init' : funcname;
	      fire = func !== '';
	      fire = fire && namespace[func];
	      fire = fire && typeof namespace[func][funcname] === 'function';

	      if (fire) {
	        namespace[func][funcname](args);
	      }
	    },
	    loadEvents: function() {
	      UTIL.fire('common');
	      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
	        UTIL.fire(classnm);
	        UTIL.fire(classnm, 'finalize');
	      });
	      UTIL.fire('common', 'finalize');
	    }
	};

	
	$(document).ready(UTIL.loadEvents);


	Tablesaw.init();

})(jQuery); 
